import styled from "styled-components";

export const CardSection = styled.div``;
export const CardImage = styled.div``;
export const CardContent = styled.div`
    .title {
        margin-bottom: 20px;
    }
`;

